<template>
  <HomeLayout>
    <template #content>
      <div class="mt-16 xl:mt-48">
        <div class="grid grid-cols-4 gap-8">
          <div class="col-span-1 hidden lg:block">
            <div class="fixed font-bold space-y-4 hidden xl:block">
              <a href="#legal" class="uppercase border-b pb-2 cursor-pointer hover:text-space-green block">{{ $t('terms.anchor.legal') }}</a>
              <a href="#condition" class="uppercase border-b pb-2 cursor-pointer hover:text-space-green block">{{ $t('terms.anchor.terms_of_use') }}</a>
              <a href="#politique" class="uppercase border-b pb-2 cursor-pointer hover:text-space-green block">{{ $t('terms.anchor.confidentiality') }}</a>
            </div>
          </div>
          <div class="text-center col-span-4 lg:col-span-3">
            <div id="legal">
              <div class="w-5/5 lg:w-4/5 text-left">
                <h2 class="uppercase geminis text-4xl">{{ $t('terms.legal_mention.title') }}</h2>
                <h3 class="uppercase geminis text-2xl mt-7">{{ $t('terms.legal_mention.definition.title') }}</h3>
                <p>
                  <b>{{ $t('terms.legal_mention.definition.client.title') }}</b>{{ $t('terms.legal_mention.definition.client.text') }}<br>
                  <b>{{ $t('terms.legal_mention.definition.service.title') }}</b> <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.definition.service.text') }}
                </p>
                <p>
                  <b>{{ $t('terms.legal_mention.definition.content.title') }}</b> {{ $t('terms.legal_mention.definition.content.text') }}
                </p>
                <p>
                  <b>{{ $t('terms.legal_mention.definition.client_info.title') }}</b> {{ $t('terms.legal_mention.definition.client_info.text1') }} <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.definition.client_info.text2') }}</p>
                <p>
                  <b>{{ $t('terms.legal_mention.definition.user.title') }}</b> {{ $t('terms.legal_mention.definition.user.text') }}</p>
                <p>
                  <b>{{ $t('terms.legal_mention.definition.presonal_info.title') }}</b> {{ $t('terms.legal_mention.definition.presonal_info.text') }}
                </p>
                <p>{{ $t('terms.legal_mention.definition.text') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">1. {{ $t('terms.legal_mention.presentation.title') }}</h3>
                <p>{{ $t('terms.legal_mention.presentation.text1.part1') }} <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.presentation.text1.part2') }}</p>
                <p><strong>{{ $t('terms.legal_mention.presentation.owner.title') }}</strong> :  {{ $t('terms.legal_mention.presentation.owner.title') }}<br>
                  <strong>{{ $t('terms.legal_mention.presentation.publication.title') }}</strong> {{ $t('terms.legal_mention.presentation.publication.text') }}<br>
                  <strong>{{ $t('terms.legal_mention.presentation.webmaster.title') }}</strong> {{ $t('terms.legal_mention.presentation.webmaster.text') }}<br>
                  <strong>{{ $t('terms.legal_mention.presentation.host.title') }}</strong> {{ $t('terms.legal_mention.presentation.host.text') }}<br>
                  <strong>{{ $t('terms.legal_mention.presentation.delegated.title') }}</strong> {{ $t('terms.legal_mention.presentation.delegated.text') }}<br>
                </p>
                <p>{{ $t('terms.legal_mention.presentation.text2.part1') }} <a href="https://fr.orson.io/1371/generateur-mentions-legales">{{ $t('terms.legal_mention.presentation.text2.part2') }}</a></p>

                <h3 class="uppercase geminis text-2xl mt-7">2. {{ $t('terms.legal_mention.condition.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.condition.paragraph1') }}
                </p>
                <p>{{ $t('terms.legal_mention.condition.paragraph2.part1') }} <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.condition.paragraph2.part2') }} <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.condition.paragraph2.part3') }}</p>
                <p>{{ $t('terms.legal_mention.condition.paragraph3.part1') }} <router-link to="/">https://spacecode.app</router-link>{{ $t('terms.legal_mention.condition.paragraph3.part2') }}<router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.condition.paragraph3.part3') }} <router-link to="/">https://spacecode.app</router-link>{{ $t('terms.legal_mention.condition.paragraph3.part4') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">3. {{ $t('terms.legal_mention.description.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.description.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.description.paragraph1.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.description.paragraph1.3') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.description.paragraph1.4') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.description.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.description.paragraph2.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.description.paragraph2.3') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">4. {{ $t('terms.legal_mention.contractual_limitation.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.contractual_limitation.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.contractual_limitation.paragraph1.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.contractual_limitation.paragraph2') }}
                </p>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.contractual_limitation.paragraph3') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">5. {{ $t('terms.legal_mention.intellectual.title') }} </h3>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.intellectual.paragraph1') }}
                  <router-link to="/">https://spacecode.app</router-link>.
                </p>
                <p>
                  {{ $t('terms.legal_mention.intellectual.paragraph2') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">6. {{ $t('terms.legal_mention.limitation.title') }}</h3>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph1.2') }}
                </p>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph2.2') }}
                </p>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph3.1') }}
                  <router-link to="/">https://spacecode.app</router-link>.
                  {{ $t('terms.legal_mention.limitation.paragraph3.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph3.3') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.limitation.paragraph3.4') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">7. {{ $t('terms.legal_mention.personal_data.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.personal_data.paragraph') }}
                </p>

                <h4 class="uppercase font-bold text-xl mt-5">7.1 {{ $t('terms.legal_mention.personal_data.first_part.title') }}</h4>
                <p>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph1.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph2.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph2.3') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph2.4') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.first_part.paragraph2.5') }}
                </p>

                <h4 class="uppercase font-bold text-xl mt-5">7.2 {{ $t('terms.legal_mention.personal_data.second_part.title') }}</h4>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.second_part.paragraph1') }}
                </p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.legal_mention.personal_data.second_part.list.el1') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.second_part.list.el2') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.second_part.list.el3') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.second_part.list.el4.1') }} <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.personal_data.second_part.list.el4.2') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.second_part.list.el5') }}</li>
                </ul>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.second_part.paragraph2') }}
                </p>

                <h4 class="uppercase font-bold text-xl mt-5">7.3 {{ $t('terms.legal_mention.personal_data.third_part.title') }}</h4>
                <p>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph1.2') }}
                </p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.legal_mention.personal_data.third_part.list.el1') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.third_part.list.el2') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.third_part.list.el3') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.third_part.list.el4') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.third_part.list.el5') }}</li>
                  <li>{{ $t('terms.legal_mention.personal_data.third_part.list.el6.1') }} <router-link to="/">https://spacecode.app</router-link> {{ $t('terms.legal_mention.personal_data.third_part.list.el6.2') }}</li>
                </ul>
                <p>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph2.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph2.3') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph3.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph3.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph3.3') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph4.1') }}
                  <br>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph4.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph5.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph5.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph6.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph6.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.third_part.paragraph6.3') }}
                </p>

                <h4 class="uppercase font-bold text-xl mt-5">7.4 {{ $t('terms.legal_mention.personal_data.fourth_part.title') }}</h4>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph1.2') }}
                </p>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph2.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph2.3') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph3.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph3.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph4.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.personal_data.fourth_part.paragraph4.2') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">8. {{ $t('terms.legal_mention.notification.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.notification.paragraph1') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.notification.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.notification.paragraph2.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.notification.paragraph2.3') }}
                  <router-link to="/">https://spacecode.app</router-link>.
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">{{ $t('terms.legal_mention.security.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.security.paragraph1.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.security.paragraph1.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.security.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.security.paragraph2.2') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">9. {{ $t('terms.legal_mention.cookies_tags.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.paragraph.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.paragraph.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.paragraph.3') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.paragraph.4') }}
                </p>

                <h4 class="uppercase font-bold text-xl mt-5">9.1. {{ $t('terms.legal_mention.cookies_tags.cookies.title') }}</h4>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph1') }}
                </p>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph2.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph2.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph3.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph3.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph4.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph4.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph5.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph5.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph5.3') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph5.4') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph6.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph6.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph7.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph7.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph7.3') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph8.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph8.2') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.cookies.paragraph8.3') }}
                </p>

                <h4 class="uppercase font-bold text-xl mt-5">9.2. {{ $t('terms.legal_mention.cookies_tags.tags.title') }}</h4>
                <p>
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.tags.paragraph1') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.tags.paragraph2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.tags.paragraph3.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.tags.paragraph3.2') }}
                </p>
                <p>
                  {{ $t('terms.legal_mention.cookies_tags.tags.paragraph4.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.cookies_tags.tags.paragraph4.2') }}
                </p>

                <h3 class="uppercase geminis text-2xl mt-7">10. {{ $t('terms.legal_mention.jurisdiction.title') }}</h3>
                <p>
                  {{ $t('terms.legal_mention.jurisdiction.paragraph.1') }}
                  <router-link to="/">https://spacecode.app</router-link>
                  {{ $t('terms.legal_mention.jurisdiction.paragraph.2') }}
                </p>
              </div>
            </div>
            <div id="condition">
              <div class="w-5/5 lg:w-4/5 text-left mt-10">
                <h2 class="uppercase geminis text-4xl">{{ $t('terms.general_terms.title') }}</h2>
                <p>{{ $t('terms.general_terms.paragraph1') }}</p>
                <p>{{ $t('terms.general_terms.paragraph2') }} <router-link to="/">https://spacecode.app</router-link>.</p>
                <p>{{ $t('terms.general_terms.paragraph3') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">1. {{ $t('terms.general_terms.cookies.title') }}</h3>
                <p>{{ $t('terms.general_terms.cookies.paragraph1') }}</p>
                <p>{{ $t('terms.general_terms.cookies.paragraph2') }}</p>
                <p>{{ $t('terms.general_terms.cookies.paragraph3') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">2. {{ $t('terms.general_terms.licence.title') }}</h3>
                <p>{{ $t('terms.general_terms.licence.paragraph1') }}</p>
                <p>{{ $t('terms.general_terms.licence.paragraph2') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.general_terms.licence.list1.el1') }}</li>
                  <li>{{ $t('terms.general_terms.licence.list1.el2') }}</li>
                  <li>{{ $t('terms.general_terms.licence.list1.el3') }}</li>
                  <li>{{ $t('terms.general_terms.licence.list1.el4') }}</li>
                </ul>
                <p>{{ $t('terms.general_terms.licence.paragraph3') }}</p>
                <p>{{ $t('terms.general_terms.licence.paragraph4') }}</p>
                <p>{{ $t('terms.general_terms.licence.paragraph5') }}</p>
                <p>{{ $t('terms.general_terms.licence.paragraph6') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.general_terms.licence.list2.el1') }}</li>
                  <li>{{ $t('terms.general_terms.licence.list2.el2') }}</li>
                  <li>{{ $t('terms.general_terms.licence.list2.el3') }}</li>
                  <li>{{ $t('terms.general_terms.licence.list2.el4') }}</li>
                </ul>
                <p>{{ $t('terms.general_terms.licence.paragraph7') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">3. {{ $t('terms.general_terms.hyperlinks.title') }}</h3>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph1') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.general_terms.hyperlinks.list1.el1') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list1.el2') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list1.el3') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list1.el4') }}</li>
                </ul>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph2') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.general_terms.hyperlinks.list2.el1') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list2.el2') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list2.el3') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list2.el4') }}</li>
                </ul>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph3') }}</p>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph4') }}</p>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph5') }}</p>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph6') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.general_terms.hyperlinks.list3.el1') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list3.el2') }}</li>
                  <li>{{ $t('terms.general_terms.hyperlinks.list3.el3') }}</li>
                </ul>
                <p>{{ $t('terms.general_terms.hyperlinks.paragraph7') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">4. {{ $t('terms.general_terms.responsibility.title') }}</h3>
                <p>{{ $t('terms.general_terms.responsibility.paragraph') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">5. {{ $t('terms.general_terms.reserve.title') }}</h3>
                <p>{{ $t('terms.general_terms.reserve.paragraph') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">6. {{ $t('terms.general_terms.removal_links.title') }}</h3>
                <p>{{ $t('terms.general_terms.removal_links.paragraph1') }}</p>
                <p>{{ $t('terms.general_terms.removal_links.paragraph2') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">7. {{ $t('terms.general_terms.disclaimer.title') }}</h3>
                <p>{{ $t('terms.general_terms.disclaimer.paragraph1') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.general_terms.disclaimer.list.el1') }}</li>
                  <li>{{ $t('terms.general_terms.disclaimer.list.el2') }}</li>
                  <li>{{ $t('terms.general_terms.disclaimer.list.el3') }}</li>
                  <li>{{ $t('terms.general_terms.disclaimer.list.el4') }}</li>
                </ul>
                <p>{{ $t('terms.general_terms.disclaimer.paragraph2') }}</p>
                <p>{{ $t('terms.general_terms.disclaimer.paragraph3') }}</p>

              </div>
            </div>
            <div id="politique">
              <div class="w-5/5 lg:w-4/5 text-left mt-10">
                <h2 class="uppercase geminis text-4xl">{{ $t('terms.confidentiality_politic.title') }}</h2>

                <strong>{{ $t('terms.confidentiality_politic.sub_title') }}</strong>
                <p>{{ $t('terms.confidentiality_politic.paragraph') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">1. {{ $t('terms.confidentiality_politic.personal_info.title') }}</h3>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.confidentiality_politic.personal_info.list') }}</li>
                </ul>
                <p>{{ $t('terms.confidentiality_politic.personal_info.paragraph') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">2. {{ $t('terms.confidentiality_politic.form_interactivity.title') }}</h3>
                <p>{{ $t('terms.confidentiality_politic.form_interactivity.paragraph1') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list1') }}</li>
                </ul>
                <p>{{ $t('terms.confidentiality_politic.form_interactivity.paragraph2') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list2.el1') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list2.el2') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list2.el3') }}</li>
                </ul>
                <p>{{ $t('terms.confidentiality_politic.form_interactivity.paragraph3') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list3') }}</li>
                </ul>
                <p>{{ $t('terms.confidentiality_politic.form_interactivity.paragraph4') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list4.el1') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list4.el2') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.form_interactivity.list4.el3') }}</li>
                </ul>



                <h3 class="uppercase geminis text-2xl mt-7">3. {{ $t('terms.confidentiality_politic.opposition_withdrawal.title') }}</h3>
                <p>{{ $t('terms.confidentiality_politic.opposition_withdrawal.paragraph1') }}</p>
                <p>{{ $t('terms.confidentiality_politic.opposition_withdrawal.paragraph2') }}</p>
                <p>{{ $t('terms.confidentiality_politic.opposition_withdrawal.paragraph3') }}</p>
                <p>{{ $t('terms.confidentiality_politic.opposition_withdrawal.paragraph4') }}</p>
                <p>{{ $t('terms.confidentiality_politic.opposition_withdrawal.paragraph5') }} <router-link to="/contact">https://www.spacecode.app/contact</router-link> </p>

                <h3 class="uppercase geminis text-2xl mt-7">4. {{ $t('terms.confidentiality_politic.access_right.title') }}</h3>
                <p>{{ $t('terms.confidentiality_politic.access_right.paragraph1') }}</p>
                <p>{{ $t('terms.confidentiality_politic.access_right.paragraph2') }}</p>
                <p>{{ $t('terms.confidentiality_politic.access_right.paragraph3') }}</p>
                <p>{{ $t('terms.confidentiality_politic.access_right.paragraph4') }} <router-link to="/contact">https://www.spacecode.app/contact</router-link> </p>

                <h3 class="uppercase geminis text-2xl mt-7">5. {{ $t('terms.confidentiality_politic.security.title') }}</h3>
                <p>{{ $t('terms.confidentiality_politic.security.paragraph1') }}</p>
                <p>{{ $t('terms.confidentiality_politic.security.paragraph2') }}</p>
                <ul class="list-disc px-4">
                  <li>{{ $t('terms.confidentiality_politic.security.list.el1') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.security.list.el2') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.security.list.el3') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.security.list.el4') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.security.list.el5') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.security.list.el6') }}</li>
                  <li>{{ $t('terms.confidentiality_politic.security.list.el7') }}</li>
                </ul>
                <p>{{ $t('terms.confidentiality_politic.security.paragraph3') }}</p>

                <h3 class="uppercase geminis text-2xl mt-7">6. {{ $t('terms.confidentiality_politic.legislation.title') }}</h3>
                <p>{{ $t('terms.confidentiality_politic.security.paragraph1') }}</p>
                <p>{{ $t('terms.confidentiality_politic.legislation.paragraph2') }} <a href="https://www.cnil.fr/">https://www.cnil.fr/</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-col items-center justify-center mt-10">
        <img
          :data-src="require('@/assets/images/dotcode.png')"
          alt="Dotcode"
          class="z-10 w-1/2 md:w-auto"
        >
        <div class="geminis">
          Made with love by l'équipe 12
        </div>
        <div class="geminis">
          2022, Dotcode
        </div>
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from '@/layouts/HomeLayout';
export default {
  name: 'Terms',
  components: { HomeLayout },
  mounted() {
    if (this.$route.hash) {
      const element = document.querySelector(this.$route.hash);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
